<template>
    <div class="num_charts" ref="box_charts">
        <div ref="times_charts" :style="boxStyle"></div>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            boxStyle: {},
            orgName: [],
            routeRunShortNumSum: [],
            routeRunTimeoutNumSum: [],
            siteRunShortNumSum: [],
            siteRunTimeoutNumSum: [],
            totalSum: [],
            chart: null
        }
    },
    created() {
        this.$nextTick(() => {
            this.setStyle()
            this.setObserver()
        })
    },
    props: {
        list: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    watch: {
        list: {
            immediate: true,
            handler: function(val) {
                if (val) {
                    let l_s = [],
                        l_on = [],
                        l_off = [],
                        l_total = [],
                        l_st = [],
                        l_tt = []

                    val.forEach(item => {
                        l_s.push(item.orgName)
                        l_on.push(item.routeRunShortNumSum)
                        l_off.push(item.routeRunTimeoutNumSum)
                        l_total.push(item.siteRunShortNumSum)
                        l_st.push(item.siteRunTimeoutNumSum)
                        l_tt.push(item.totalSum)
                    })
                    this.orgName = l_s
                    this.routeRunShortNumSum = l_on
                    this.routeRunTimeoutNumSum = l_off
                    this.siteRunShortNumSum = l_total
                    this.siteRunTimeoutNumSum = l_st
                    this.totalSum = l_tt
                    console.log(this.orgName)
                    console.log(this.routeRunShortNumSum)
                    console.log(this.routeRunTimeoutNumSum)
                    this.$nextTick(() => {
                        this.setStyle()
                    })
                } else {
                    this.orgName = []
                    this.routeRunShortNumSum = []
                    this.routeRunTimeoutNumSum = []
                    this.siteRunShortNumSum = []
                    this.siteRunTimeoutNumSum = []
                    this.totalSum = []
                    this.$nextTick(() => {
                        this.setStyle()
                    })
                }
            }
        }
    },
    methods: {
        setObserver() {
            let myObserve = new ResizeObserver(() => {
                echarts.dispose(this.chart)
                this.setStyle()
            })
            myObserve.observe(this.$el)
        },
        setChart() {
            var userChart = echarts.init(this.$refs['times_charts'])
            var option = {
                title: {
                    text: '分公司线路运行时长异常次数统计图',
                    x: 'center',
                    y: 'top',
                    textAlign: 'left'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: [
                        '线路过短次数',
                        '线路超时次数',
                        '站间过短次数',
                        '站间超时次数',
                        '总次数'
                    ],
                    left: 'center',
                    bottom: '-4px'
                },
                grid: {
                    // top: '160px'
                    // containLabel: true
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        data: this.orgName
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '线路过短次数',
                        type: 'bar',
                        data: this.routeRunShortNumSum
                    },
                    {
                        name: '线路超时次数',
                        type: 'bar',
                        data: this.routeRunTimeoutNumSum
                    },

                    {
                        name: '站间过短次数',
                        type: 'bar',
                        data: this.siteRunShortNumSum
                    },
                    {
                        name: '站间超时次数',
                        type: 'bar',
                        data: this.siteRunTimeoutNumSum
                    },
                    {
                        name: '总次数',
                        type: 'line',
                        data: this.totalSum
                    }
                ]
            }

            userChart.setOption(option)
            this.chart = userChart
        },
        setStyle() {
            let s = this.$refs['box_charts']
            let h = $(s).height() + 'px'
            console.log(h)
            this.boxStyle = {
                height: h
            }
            this.$nextTick(() => {
                this.setChart()
            })
        }
    }
}
</script>
<style lang="scss" scoped="num_charts">
.num_charts {
    height: 100%;
}
</style>
