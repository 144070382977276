<template>
    <div class="total_charts">
        <div v-bind:style="boxStyle" ref="times_charts"></div>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            boxStyle: {},
            legends: [],
            orgName: [],
            series: [],
            chart: null
        }
    },
    created() {
        this.$nextTick(() => {
            this.setStyle()
            this.setObserver()
        })
    },
    props: {
        list: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    watch: {
        list: {
            immediate: true,
            handler: function(val) {
                console.log(val)
                let l = []
                let o = []
                let s = []
                if (val[0]) {
                    val[0].monthValues.forEach(item => {
                        l.push(item.month)
                    })
                    this.legends = l
                    console.log(this.legends)
                    val.forEach(item => {
                        o.push(item.orgName)
                    })
                    this.orgName = o
                    console.log(this.orgName)

                    l.forEach(item => {
                        s.push({
                            name: item,
                            type: 'bar',
                            data: []
                        })
                    })
                    s.forEach(item => {
                        val.forEach(it => {
                            it.monthValues.forEach(i => {
                                if (item.name == i.month) {
                                    item.data.push(i.totalSum)
                                }
                            })
                        })
                    })
                    this.series = s
                    console.log(this.series)
                    this.$nextTick(() => {
                        this.setStyle()
                    })
                }
            }
        }
    },
    methods: {
        setObserver() {
            let myObserve = new ResizeObserver(() => {
                echarts.dispose(this.chart)
                this.setChart()
            })
            myObserve.observe(this.$el)
        },
        setChart() {
            var userChart = echarts.init(this.$refs['times_charts'])
            var option = {
                title: {
                    text: '分公司运行过短、超时总次数对比',
                    x: 'center',
                    y: 'top',
                    textAlign: 'left'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: this.legends,
                    left: 'center',
                    bottom: '10px'
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        data: this.orgName
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: this.series
            }

            userChart.setOption(option)
            this.chart = userChart
        },
        setStyle() {
            let s = this.$refs['times_charts']
            let w = $(s).width()
            let h = w * 0.6 + 'px'
            console.log(w)
            this.boxStyle = {
                height: h
            }
            this.$nextTick(() => {
                this.setChart()
            })
        }
    }
}
</script>