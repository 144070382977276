<template>
    <div class="detail_box">
        <div>
            <el-button type="primary" @click="update" class="detail_button">返回上一级</el-button>
        </div>
        <jy-table :data="dataList" header-cell-class-name="bg_color" border>
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="motorcadeName" label="车队"></jy-table-column>
            <jy-table-column prop="routeName" label="线路"></jy-table-column>
            <jy-table-column prop="dirLabel" label="上下行"></jy-table-column>
            <!-- <jy-table-column prop="loginUser" label="类型"></jy-table-column> -->
            <jy-table-column prop="startSiteName" label="开始站点"></jy-table-column>
            <jy-table-column prop="endSiteName" label="结束站点"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
            <jy-table-column prop="driverName" label="驾驶员"></jy-table-column>
            <jy-table-column prop="warningTime" label="报警日期"></jy-table-column>
            <jy-table-column prop="warningType" label="报警类型"></jy-table-column>
            <jy-table-column prop="settingTimeLen" label="设定时长(分)"></jy-table-column>
            <jy-table-column prop="actualTimeLen" label="实际时长(秒)"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [{
                motorcadeName: '',
                routeName: '',
                dirLabel: '',
                // loginUser: '',
                startSiteName: '',
                endSiteName: '',
                vehicleNo: '',
                driverName: '',
                warningTime: '',
                warningType: '',
                settingTimeLen: '',
                actualTimeLen: ''
            }]
        }
    },
    methods: {
        init(row) {
            this.getList(row)
        },
        getList(row) {
            let url = '/statrouteruntimedaily/queryRouteRunTimeDetail'
            let option = {
                ...row,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.dataList = detail.list
                this.total = detail.total
            })
        },
        update() {
            this.$emit('update')
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        }
    }
}

</script>
<style lang="scss" scoped="detail_box">
.detail_box {
    .detail_button {
        margin-top: 20px;
    }
}

</style>
